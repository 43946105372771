import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Formik } from "formik";
import { clone, isEmpty } from "underscore";

import { commonApiFN } from "../../common/api";
import { DeleteAccount } from "../../common/module_wise_api_url/common";
import {
  UpdateAdmin,
  updateInterstrideTeam,
} from "../../common/module_wise_api_url/manageUser";
import { getWebinarTimezones } from "../../common/module_wise_api_url/post";
import Button from "../../components/Button/Button";
import CustomDropdown from "../../components/CustomDropdown/Cutsom-Dropdown";
import Dialog from "../../components/Dialog/Dialog";
import FileUpload from "../../components/FileUpload/FileUpload";
import Textbox from "../../components/Textbox/Textbox";
import { NATIONALITY_OPTIONS, userTypes } from "../../Constants";
import { resetUserData, updateUserData } from "../../redux/action";
import {
  clearLocalStorage,
  fileSizeExceeds,
  onUploadReject,
  toastify,
  validateAttribute,
  validateEmail,
  validateFile,
  validateName,
  validatePhoneNumber,
  validatePosition,
  validateString,
} from "../../helper/helper";
import ButtonCustom from "../../components/Button/Button";
import PhoneNumberSelect from "../../components/PhoneNumberSelect/PhoneNumberSelect";

function MyProfile(props) {
  const dispatch = useDispatch();
  const {
    isSuperAdmin = false,
    isSchoolAdmin = false,
    isAdminAmbassador = false,
    user = {},
    sso,
  } = useSelector((state) => state.loginReducer);

  const [email] = useState(user.email);
  const [password, setPassword] = useState("123456789012");
  const [timeZone, setTimeZone] = useState("");
  const [timezonesList, setTimezonesList] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState({
    file: "",
    icon: user.user_profile_url,
  });
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [admin_position, setAdminPosition] = useState(user.admin_position);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isClosingAccount, setIsClosingAccount] = useState(false);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const applySizeLimitInMB = 3;

  const handleProfilePicCallback = useCallback((file) => {
    file.forEach((item) => {
      if (!["image/png", "image/jpg", "image/jpeg"].includes(item.type)) {
        toastify("error", "Please select a valid image. (png, jpg, or jpeg)");
      } else if (fileSizeExceeds(item.size, applySizeLimitInMB)) {
        toastify("error", `Please select an file < ${applySizeLimitInMB}MB.`);
      } else {
        file.forEach((item) => {
          setProfilePhoto({
            icon: URL.createObjectURL(item),
            file: file,
          });
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpdateAdminProfile = async (values) => {
    setIsUpdatingProfile(true);
    const requestData = isSuperAdmin
      ? clone(updateInterstrideTeam)
      : clone(UpdateAdmin);

    requestData.url = `${requestData.url}${user.id}`;

    const adminFormData = new FormData();

    if (isSuperAdmin) {
      adminFormData.append("first_name", firstName.trim());
      adminFormData.append("last_name", lastName.trim());
      adminFormData.append("admin_position", admin_position.trim());
      adminFormData.append("email", email.trim());
      adminFormData.append("time_zone", timeZone.timezone_name_value);
      adminFormData.append("timezone_id", timeZone.id);
      if (values.intro) adminFormData.append("intro", values.intro.trim());
      if (profilePhoto.file && profilePhoto.file.length > 0)
        adminFormData.append("user_profile", profilePhoto.file[0]);
    } else {
      adminFormData.append("user[first_name]", firstName.trim());
      adminFormData.append("user[last_name]", lastName.trim());
      adminFormData.append("user[admin_position]", admin_position.trim());
      adminFormData.append("user[email]", email.trim());
      adminFormData.append("user[time_zone]", timeZone.timezone_name_value);
      adminFormData.append("timezone_id", timeZone.id);
      if (values.intro)
        adminFormData.append("user[intro]", values.intro.trim());
      if (profilePhoto.file && profilePhoto.file.length > 0)
        adminFormData.append("user[user_profile]", profilePhoto.file[0]);
      if (isAdminAmbassador) {
        adminFormData.append("user[phone_number]", values.phone_number);
        adminFormData.append("user[country_code]", values.country_code);
      }
      if (!isSuperAdmin && validateAttribute(values.nationality))
        adminFormData.append("user[nationality]", values.nationality);
    }

    requestData.data = adminFormData;
    requestData.notify = true;

    const response = await commonApiFN(requestData);
    if (response.success) {
      const { user = {} } = response.data;
      dispatch(updateUserData(user));
      if (props.FirstTimeLogin) props.history.push("/");
    }
    setIsUpdatingProfile(false);
  };

  const handleLogout = () => {
    try {
      dispatch(resetUserData());
      sessionStorage.clear();
      clearLocalStorage();
      window.kommunicate.logout();
      props.history.push("/");
    } catch (error) {
      console.log(JSON.stringify(error));
    } finally {
      setIsLoggingOut(false);
    }
  };

  useEffect(() => {
    async function getTimezones() {
      const requestData = clone(getWebinarTimezones);
      const response = await commonApiFN(requestData);

      if (response && response.data && response.data.length) {
        setTimezonesList(response.data);
      } else {
        setTimezonesList([]);
      }
    }
    getTimezones();
  }, []);

  useEffect(() => {
    if (timezonesList.length > 0) {
      timezonesList.find((i) => {
        if (i.timezone_name_value.indexOf(user.time_zone) !== -1) {
          setTimeZone(i);
        }
        return i.timezone_name_value.indexOf(user.time_zone) !== -1;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezonesList]);

  const handleCloseAccount = async () => {
    try {
      const request = clone(DeleteAccount);
      const response = await commonApiFN(request);
      if (response.success) {
        toastify("success", response.message);
        handleLogout();
      } else {
        toastify("error", response.message);
      }
    } catch (error) {
      console.log(error);
      toastify("error", error);
    } finally {
      setIsClosingAccount(false);
    }
  };

  return (
    <div className="profile-content">
      <div className="profile-header">
        <h2 className="text-center page-title profile-title admin-profile-text">
          Your Profile
        </h2>
        <Button
          type="secondary"
          onClick={() => setShowLogoutPopup(true)}
          disableElevation
        >
          Log out
        </Button>
      </div>
      <Formik
        initialValues={{
          userName: "",
          email: "",
          firstName: "",
          lastName: "",
          admin_position: "",
          time_zone: "",
          intro: user.intro,
          phone_number: user?.phone_number,
          country_code: user?.country_code,
          nationality: user?.nationality,
        }}
        validate={(values) => {
          const errors = {};

          if (isAdminAmbassador && !profilePhoto.icon) {
            if (validateFile(profilePhoto.file)) {
              errors.file = "Please select Profile picture";
            }
          }

          if (validateString(email)) {
            errors.email = "Please enter Email";
          } else if (validateEmail(email)) {
            errors.email = "Please enter valid Email";
          }

          if (validateString(firstName)) {
            errors.firstName = "Please enter First name";
          } else if (validateName(firstName)) {
            errors.firstName = "Please enter valid First name";
          }

          if (validateString(lastName)) {
            errors.lastName = "Please enter Last name";
          } else if (validateName(lastName)) {
            errors.lastName = "Please enter valid Last name";
          }

          if (validateString(admin_position)) {
            errors.admin_position = "Please enter position";
          } else if (validatePosition(admin_position)) {
            errors.admin_position = "Please enter valid position";
          }
          if (isEmpty(timeZone)) {
            errors.time_zone = "Please select time zone";
          }

          if (validateString(values.intro)) {
            errors.intro = "Please enter introduction";
          }

          if (isAdminAmbassador) {
            if (validatePhoneNumber(values.phone_number, values.country_code)) {
              errors.phone_number = "Please enter Phone number";
            }
          }

          console.log(errors);
          return errors;
        }}
        onSubmit={(values) => {
          UpdateAdminProfile(values);
        }}
      >
        {({
          isValid,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="profile-form">
              <div className="row">
                <div className="col-md-12 admin-profile-form-wrapper mb-50">
                  <div className="form-group admin-profile-photo-wrapper">
                    <FileUpload
                      vallidFiles="image/*"
                      handleUploadFile={handleProfilePicCallback}
                      icon={profilePhoto.icon}
                      type={
                        profilePhoto.file
                          ? profilePhoto.file[0].type
                          : undefined
                      }
                      imagePreview={true}
                      applySizeLimitInMB={applySizeLimitInMB}
                      cropBoxResizable={false}
                      aspectRatio={1}
                      cropBoxMovable={false}
                      cropperType="circle"
                      onReject={onUploadReject}
                      error={errors?.file}
                      errorText={errors?.file}
                    />
                    <span>
                      Upload a profile picture{isAdminAmbassador ? "*" : ""}
                    </span>
                  </div>
                  <div className="form-group admin-profile-first-last-name-wrapper admin-profile-text">
                    <Textbox
                      name="firstName"
                      label="First name"
                      placeholder="First name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      onBlur={handleBlur}
                      error={errors.firstName && touched.firstName}
                      errorText={errors.firstName}
                      maxLength={20}
                    />
                    <Textbox
                      name="lastName"
                      label="Last name"
                      placeholder="Last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      onBlur={handleBlur}
                      error={errors.lastName && touched.lastName}
                      errorText={errors.lastName}
                      maxLength={20}
                    />
                  </div>
                  <div className="form-group textareaGroup admin-profile-introduction-wrapper">
                    <Textbox
                      name="intro"
                      label="Introduction"
                      placeholder="Give a short intro about who you are. This will be visible to students in the Network portal."
                      value={values.intro}
                      onChange={handleChange}
                      isMultiline={true}
                      rows={5}
                      onBlur={handleBlur}
                      error={errors.intro && touched.intro}
                      errorText={errors.intro}
                    />
                  </div>
                  <div className="form-group admin-profile-position-email-wrapper">
                    <Textbox
                      name="admin_position"
                      label="Position"
                      placeholder="Position"
                      value={admin_position}
                      onChange={(e) => setAdminPosition(e.target.value)}
                      onBlur={handleBlur}
                      error={errors.admin_position && touched.admin_position}
                      errorText={errors.admin_position}
                      maxLength={30}
                    />
                    <Textbox
                      label="Enter email"
                      name="email"
                      placeholder="Enter email"
                      value={email}
                      disabled={true}
                    />
                  </div>

                  {!isSuperAdmin && (
                    <div
                      className={`form-group ${
                        isAdminAmbassador
                          ? "admin-profile-position-email-wrapper"
                          : "admin-profile-phone-number-wrapper"
                      }`}
                    >
                      {isAdminAmbassador && (
                        <PhoneNumberSelect
                          name="phone_number"
                          // label="Phone number*"
                          onChange={(phone_number, country_code) => {
                            setFieldValue(
                              "phone_number",
                              String(phone_number).replace(country_code, "")
                            );
                            setFieldValue("country_code", country_code);
                          }}
                          value={
                            `${values?.country_code?.toString()}${values?.phone_number?.toString()}` ??
                            ""
                          }
                          placeholder="Enter phone number"
                          onBlur={handleBlur}
                          error={errors.phone_number}
                          errorText={errors.phone_number}
                        />
                      )}
                      <CustomDropdown
                        data={NATIONALITY_OPTIONS}
                        onClick={(value) => {
                          setFieldValue("nationality", value.name);
                        }}
                        displayValue="name"
                        displayLabel="name"
                        placeholder="Select nationality"
                        value={
                          values.nationality
                            ? NATIONALITY_OPTIONS.find(
                                (rec) => rec.name === values.nationality
                              )
                            : null
                        }
                        noSelectionText="Select nationality"
                      />
                    </div>
                  )}

                  {!sso && (
                    <div className="form-group admin-profile-password-wrapper">
                      <Textbox
                        type="password"
                        label="Password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={true}
                      />
                      <div className="text-center change-password">
                        <Link to={"/change-password"}>
                          <span className="admin-profile-text">
                            Change password
                          </span>
                        </Link>
                      </div>
                      {isSchoolAdmin ||
                      user.user_type === userTypes.GENERAL_ADVISOR ? (
                        <div className="text-center close-account">
                          <button
                            type="button"
                            onClick={(e) => {
                              setShowDeletePopup(true);
                            }}
                          >
                            Close account
                          </button>
                        </div>
                      ) : null}
                    </div>
                  )}

                  <div className="form-group admin-profile-time-zone-wrapper mb-20">
                    <CustomDropdown
                      data={timezonesList}
                      onClick={(value) => {
                        setTimeZone(value);
                      }}
                      displayValue="timezone_name_value"
                      displayLabel="timezone_name_label"
                      placeholder="Select Time Zone"
                      value={timeZone}
                      noSelectionText="Select Time Zone"
                      error={errors.time_zone && touched.time_zone}
                      errorText={errors.time_zone}
                    />
                  </div>
                </div>
              </div>
            </div>
            <footer className="content-footer">
              <ButtonCustom
                type="primary"
                className="primary-button"
                submit={true}
                isSubmitting={isUpdatingProfile}
              >
                Save
              </ButtonCustom>
            </footer>
          </form>
        )}
      </Formik>
      <Dialog
        open={showDeletePopup}
        header={"Are you sure you want to close your account?"}
        subheader=""
        onClose={() => setShowDeletePopup(false)}
        onActionClick={() => {
          setIsClosingAccount(true);
          handleCloseAccount();
        }}
        actionButtonLabel="Confirm"
        className={"confirmation-dialogue-wrapper"}
        isActionLoading={isClosingAccount}
      />
      <Dialog
        open={showLogoutPopup}
        header={"Are you sure you want to log out?"}
        subheader=""
        onClose={() => setShowLogoutPopup(false)}
        onActionClick={() => {
          setIsLoggingOut(true);
          handleLogout();
        }}
        actionButtonLabel="Log Out"
        className={"confirmation-dialogue-wrapper"}
        isActionLoading={isLoggingOut}
      />
    </div>
  );
}

export default MyProfile;
